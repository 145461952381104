import React from 'react';
import { Button, Result } from 'antd';
import { withTranslation } from 'react-i18next';

// Assuming a LocaleContext exists and provides a formatMessage function
// If your project does not have this, you'll need to adapt the code to fit your localization approach.

class NotFoundScreen extends React.Component {

  navigateHome = () => {
    this.props.history.push('/');
  };

  render() {

    return (
      <Result
        status="404"
        title="404"
        subTitle={'Sorry, the page you visited does not exist.'}
        extra={
          <Button type="primary" onClick={this.navigateHome}>
            {'Back Home'}
          </Button>
        }
      />
    );
  }
}

export default withTranslation()(NotFoundScreen);
