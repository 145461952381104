import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Form, Input, Image } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { loginAPI } from '../apis';
import StoreManager from '../appdata';

class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
        StoreManager.rootState.thisLogin = this;
    }

    onFinished = async (form) => {
        this.setState({ isLoading: true });
        loginAPI(form.clientid, form.clientsecret);
    }

    render() {
        const { isLoading } = this.state;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                {/* <Image width={200} src="your-logo-url" preview={false} /> */}

                <p style={{ fontSize: 25, fontWeight: '500' }}>FACEX Admin</p>
                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={this.onFinished}
                >
                    <Form.Item
                        name="clientid"
                        rules={[{ required: true, message: 'Please input your client id!' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="client id" />
                    </Form.Item>
                    <Form.Item
                        name="clientsecret"
                        rules={[{ required: true, message: 'Please input your client secret!' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="client secret"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button loading={isLoading} style={{ width: 300 }} type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default withTranslation()(LoginScreen);
