import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { HomeOutlined, UserOutlined, SearchOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Image, Space, Table, Tag, Breadcrumb, Input, Select, DatePicker, Button, message, Typography, Row, Col, Modal, Descriptions, Statistic } from 'antd';
import { getClientsWidthCallBack, getDataEnroll2 } from '../apis';
import StoreManager from '../appdata';
import dayjs from 'dayjs';
import ShowMoreText from 'react-show-more-text';

const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

const isJSONString = (val) => {
  try {
    JSON.parse(val)
    return true
  } catch (error) {
    return false
  }
}

class FaceEnrollLog extends Component {
  constructor(props) {
    super(props)
    const today = dayjs().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 0);
    const sevenDaysAgo = dayjs().subtract(14, 'day').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);


    this.state = {
      enrollLogs: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dateSelected: [sevenDaysAgo, today],
      isLoading: true,
      resultFilter: null,
      requestId: '',
      userId: '',
      clients: [],
      clientSelect: []
    }
    StoreManager.rootState.thisFaceEnrollLog = this
    this.getData()
    // getClientsWidthCallBack((clients) => {
    //   this.setState({ clients: clients })
    // })

  }

  getData = () => {
    const { dateSelected, pageSize, currentPage, resultFilter, clientSelect, requestId, userId } = this.state;
    if (!dateSelected) {
      message.warning("Input Error!!");
      return;
    }
  
    this.setState({ isLoading: true });
    getDataEnroll2(dateSelected, resultFilter, requestId, userId, clientSelect, pageSize, currentPage);
  }
  

  showDetail = (enrollLog) => {
    let result = {};
    const { image = {} } = enrollLog;
  
    if (isJSONString(enrollLog?.raw_result)) {
      result = JSON.parse(enrollLog?.raw_result);
    }
  
    Modal.info({
      title: `Enroll Logs`,
      content: (
        <div style={{ marginTop: "10px" }}>
          <Descriptions title="" bordered labelStyle={{ fontWeight: 'bold' }}>
            <Descriptions.Item span={4} label="Image">
              <Row>
                <Col span={8}>
                  <Space direction="vertical" align="center">
                    <Image width={100} src={`/image/v1.0/storage/image?path=${enrollLog.image_url}`} />
                    <Text strong>Selfie</Text>
                  </Space>
                </Col>
              </Row>
            </Descriptions.Item>
  
            <Descriptions.Item span={4} label="PID">{enrollLog?.person_id}</Descriptions.Item>
            <Descriptions.Item span={4} label="Request ID">{enrollLog?.request_id}</Descriptions.Item>
          </Descriptions>
        </div>
      ),
      width: 800,
      onOk() {},
    });
  }
  

  // Nội dung trang Face Enroll Log
  render() {
    const { enrollLogs, dateSelected, isLoading, currentPage, total, pageSize, resultFilter, clients, userId, requestId } = this.state
    const columns = [
      {
        title: 'Time',
        dataIndex: 'enrolledAt',
        key: 'enrolledAt',
        render: (time) => dayjs(time).format('HH:mm --- DD-MM-YYYY')

      },
      {
        title: 'Image',
        dataIndex: 'faceImages',
        key: 'faceImages',
        width: 100,
        render: (images) => {
          return images.length > 0 ? <Image width={50} height={50} src={images[0]} /> : null
        }
      },
      {
        title: 'Person ID',
        dataIndex: 'personID',
        key: 'personID',
      },
      {
        title: 'Request ID',
        dataIndex: 'requestId',
        key: 'requestId',
      },
      {
        title: 'OCR | NFC Info',
        dataIndex: 'ocrData',
        key: 'ocrData',
        render: (ocrData) => {
          if (isJSONString(ocrData)) {
            const parsedData = JSON.parse(ocrData);
            return (
              <div>
                <p><b>Personal Number:</b> {parsedData.personalNumber}</p>
                <p><b>NFC Number:</b> {parsedData.passportNumber}</p>
                <p><b>Date of Birth:</b> {parsedData.dateOfBirth}</p>
                <p><b>Date of Expiry:</b> {parsedData.dateOfExpiry}</p>
                <p><b>Place of Birth:</b> {parsedData.placeOfBirth}</p>
                <p><b>Residence Address:</b> {parsedData.residenceAddress}</p>
                <p><b>Nationality:</b> {parsedData.nationality}</p>
                <p><b>Given Names:</b> {parsedData.givenNames}</p>
                <p><b>Surname:</b> {parsedData.surname}</p>
              </div>
            );
          }
          return <Text type="secondary">No Data</Text>;
        }
      },
      {
        title: 'Result',
        dataIndex: 'result',
        key: 'result',
        width: 150,
        render: (item) => {
            const statusMap = {
                1: { color: 'green', text: 'successful' },
                2: { color: 'green', text: 'updated successfully' },
                3: { color: 'red', text: 'updated failed' },
                4: { color: 'red', text: 'not match' },
                5: { color: 'blue', text: 'Liveness failed' },
                // Thêm các trạng thái mới ở đây nếu cần
            };
    
            const status = statusMap[item] || { color: 'red', text: 'Unknown Error' };
            return <Tag color={status.color}>{status.text}</Tag>;
        }
    },    
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Link onClick={() => { this.showDetail(record) }}>View</Link>
          </Space>
        ),
        width: 80,
      },
    ];
    let scrollY = window.innerHeight - 352

    let options = [{
      label: 'SUCCESS',
      value: 1
    }, {
      label: 'UNKNOWN ERROR',
      value: 0
    }, {
      label: 'FAILED',
      value: -1
    }, {
      label: 'LIVENESS FAILED',
      value: -2
    }]
    let clientOptions = []
    for (let i = 0; i < clients.length; i++) {
      const element = clients[i];
      clientOptions.push(
        {
          label: element.client_alias,
          value: element.client_id
        }
      )

    }

    return (<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Breadcrumb
        items={[
          {
            href: '',
            title: <> <HomeOutlined />
              <span>Home</span>
            </>,
          },
          {
            title: (
              <>
                <UserOutlined />
                <span>Face Enroll logs</span>
              </>
            ),
          }
        ]}
      />
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
        <Select
          mode="multiple"
          style={{
            width: 200
          }}
          placeholder="select client"
          onChange={(values, option) => {
            console.log("values", values, option)
            this.setState({ clientSelect: values })
          }}
          optionLabelProp="label"
          options={clientOptions}
        >
        </Select>
        <Input style={{ maxWidth: 200, marginLeft: 12 }} placeholder="Search PID" value={userId} onChange={(e) => {
          this.setState({ 'userId': e.target.value })
        }} />
        <Input style={{ maxWidth: 200, marginLeft: 12 }} placeholder="Search Request ID" value={requestId} onChange={(e) => {
          this.setState({ 'requestId': e.target.value })
        }} />
        <Select
          allowClear
          style={{ width: 200, marginLeft: 12 }}
          placeholder="Result type"
          onChange={(value, option) => {
            console.log("value", value)
            this.setState({ resultFilter: value })
          }}
          options={options}
          value={resultFilter}
        />
        <RangePicker style={{ marginLeft: 12 }} value={dateSelected} showTime onChange={(dates, dateStrings) => {
          this.setState({ 'dateSelected': dates })
        }} />
        <Button style={{ marginLeft: 12 }} type="primary" icon={<SearchOutlined />} onClick={this.getData}>Search</Button>


      </div>
      <Table loading={isLoading} style={{ marginTop: 20 }} scroll={{ y: scrollY }} columns={columns} dataSource={enrollLogs} bordered pagination={{
        current: currentPage, total: total, pageSize: pageSize, onChange: (page, pageSize) => {
          // console.log("page", pageSize, page)
          this.setState({ currentPage: page, pageSize: pageSize }, () => {
            this.getData()
          })
        }
      }} />

    </div>)
  }
}

export default withTranslation()(FaceEnrollLog);
