import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Upload, Form, Input, Image, message, Breadcrumb, Table, Tag, Row, Col, Statistic, Drawer } from 'antd';
import { PlusOutlined, SnippetsOutlined, UploadOutlined, HomeOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import resource from '../resource';
import { getClients, createClient } from '../apis';
import StoreManager from '../appdata';
import dayjs from 'dayjs';


class ClientScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            base64Face: '',
            isShowCreate: false,
            clients: [],
            isCreateLoading: false
        };
        StoreManager.rootState.thisClientScreen = this;

    }
    componentDidMount() {
       this.getData()
    }
    getData = () => {
        this.setState({ isLoading: true })
        getClients()
    }

    render() {
        const { isLoading, isShowCreate, clients, isCreateLoading } = this.state;
        let scrollY = window.innerHeight - 352

        const columns = [

            {
                title: 'Client Name',
                dataIndex: 'client_alias',
                key: 'client_alias',
                width: 200
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (item) => {
                    var tmp = <></>
                    switch (item) {
                        case 'ACTIVE':
                            tmp = <Tag color="green">ACTIVE</Tag>
                            break;
                        case 'DELETED':
                            tmp = <Tag color="orange">DELETED</Tag>
                            break;

                        default:
                            break;
                    }
                    return tmp
                }
            },

            {
                title: 'Client ID',
                dataIndex: 'client_id',
                key: 'client_id',

            },
            {
                title: 'Client Secret',
                dataIndex: 'client_secret',
                key: 'client_secret',

            },

        ];

        return (
            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Breadcrumb
                        items={[
                            {
                                href: '',
                                title: <> <HomeOutlined />
                                    <span>Home</span>
                                </>,
                            },
                            {
                                title: (
                                    <>
                                        <SnippetsOutlined />
                                        <span>Client Apps</span>
                                    </>
                                ),
                            }
                        ]}
                    />



                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12, justifyContent: 'flex-end' }}>

                    <Button style={{ marginLeft: 12 }} type="primary" icon={<PlusOutlined />} onClick={() => {
                        this.setState({ isShowCreate: true })
                    }}>Create client</Button>

                </div>
                <Table loading={isLoading} style={{ marginTop: 20 }} scroll={{ y: scrollY }} columns={columns} dataSource={clients} bordered />
                <Drawer title="Create client" placement="right" onClose={() => {
                    this.setState({ isShowCreate: false })
                }} open={isShowCreate}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={(values) => { 
                            console.log('Success:', values);
                            this.setState({isCreateLoading: true} , ()=>{
                                createClient(values.email, values.clientname)
                            })

                        }}
                        onFinishFailed={() => { }}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your Email!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Client name"
                            name="clientname"
                            rules={[{ required: true, message: 'Please input your client name!' }]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button isLoading={isCreateLoading} type="primary" htmlType="submit">
                                Create
                            </Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

export default withTranslation()(ClientScreen);
