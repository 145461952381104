export default class StoreManager {
    static userdata = {
        token: '',
        jwtDecode:{}
    }
    static appdata = {
        
    }
    static rootState = {
    }
    static async loadDataFromLocal(){
       var data = localStorage.getItem("userdata")
       if (data) {
        this.userdata = JSON.parse(data)
       }
       console.log("this.userdata", this.userdata)
    }
    static updateDataToLocal() {
        localStorage.setItem("userdata", JSON.stringify(this.userdata))
    }
}