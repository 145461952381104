

import React, { Component, useState } from 'react';
import { Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { AppstoreOutlined, SafetyCertificateOutlined, SnippetsOutlined, FieldTimeOutlined , UserAddOutlined} from '@ant-design/icons';
import StoreManager from '../appdata';

const { Text } = Typography;
const {version} = require('../../package.json')

const AppMenu = ({collapsed}) => {
    // const { selectedKey } = this.props;
    // let href = window.location.href.split('/')
    // href=href[3]

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
      <Menu theme="light" mode="inline"> 
        <Menu.Item key="1"  icon={<AppstoreOutlined />}>
          <Link to="/">Dashboard</Link>
        </Menu.Item>
        {
          StoreManager.userdata.jwtDecode?.role == "ADMIN" ?  <Menu.Item key="4" icon={<SnippetsOutlined />}>
          <Link to="/list-client">Client Apps</Link>
        </Menu.Item> : null
        }
      
        <Menu.Item key="3" icon={<FieldTimeOutlined />}>
          <Link to="/logs-authentication">Authen Logs</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<UserAddOutlined />}>
          <Link to="/logs-enroll">Enroll Logs</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<SafetyCertificateOutlined />}>
          <Link to="/face-match">FaceMatch Logs</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<SafetyCertificateOutlined />}>
          <Link to="/logs-liveness">Liveness Logs</Link>
        </Menu.Item>
        {/* <Menu.Item key="5" icon={<SafetyCertificateOutlined />}>
          <Link to="/face-verify">FaceVerify Tool</Link>
        </Menu.Item> */}
        
      </Menu>
      <Text style={{margin: "20px 20px", display: collapsed ? "none" : ""}}>{version}</Text>
    </div>
  );
      
}

export default AppMenu;
