import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { HomeOutlined, UserOutlined, SearchOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Image, Space, Table, Tag, Breadcrumb, Input, Select, DatePicker, Button, message, Modal, Row, Col, Descriptions, Statistic, Typography } from 'antd';
import { getDataAuthen, getDataAuthenDetail, getClientsWidthCallBack } from '../apis';
import StoreManager from '../appdata';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

const getResultMessage = (val) => {
  switch (val) {
    case 1: return "Authentication Successful";
    case 0: return "FaceMatch Failed";
    case -1: return "Liveness Failed";
    case -2: return "FaceDetect Failed: No Face Detected";
    case -3: return "Eyes Closed";
    case -4: return "Not Looking Straight";
    default: return "Unknown Error";
  }
};

class FaceAuthenticationLog extends Component {
  constructor(props) {
    super(props);
    const today = dayjs().endOf('day');
    const sevenDaysAgo = dayjs().subtract(14, 'day').startOf('day');
    this.state = {
      authenLogs: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dateSelected: [sevenDaysAgo, today],
      isLoading: true,
      resultFilter: null,
      requestId: '',
      userId: '',
      authenDetail: {},
      clients: [],
      clientSelect: []
    };
    this.getData();
    getClientsWidthCallBack((clients) => this.setState({ clients }));
    StoreManager.rootState.thisFaceAuthenLog = this;
  }

  getData = () => {
    const { dateSelected, pageSize, currentPage, resultFilter, requestId, userId, clientSelect } = this.state;
    if (!dateSelected) {
      message.warning("Input Error!");
      return;
    }
    this.setState({ isLoading: true });
    getDataAuthen(dateSelected, resultFilter, requestId, userId, clientSelect, pageSize, currentPage);
  };

  showDetail = () => {
   
  };

  render() {
    const { authenLogs, dateSelected, isLoading, currentPage, total, clients, pageSize, resultFilter, userId, requestId } = this.state;
    const columns = [
      {
        title: 'Time',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: 160,
        render: (time) => (
          <>
            {dayjs(time).format('HH:mm')}<br />
            {dayjs(time).format('DD-MM-YYYY')}
          </>
        )
      },
      {
        title: 'Images',
        dataIndex: 'faceImages',
        key: 'faceImages',
        width: 80,
        render: (images) => <Image width={50} height={50} src={`${images[0]}`}/>
      },
      {
        title: 'Request ID',
        dataIndex: 'requestId',
        key: 'requestId',
        width: 180
      },
      {
        title: 'Transaction ID',
        dataIndex: 'transactionID',
        key: 'transactionID',
        width: 180
      },
      {
        title: 'Result',
        dataIndex: 'result',
        key: 'result',
        width: 360,
        render: (result, item) => (
        
          <>
            <Row>
              <Col span={12}>
                <Space>
                  <Text strong>Final result:</Text>
                  {result === 1 ? <Tag color="green">SUCCESS</Tag> : <Tag color="red">FAILED</Tag>}
                </Space>
                {getResultMessage(result) && (
              <Row>
                <Text italic style={{ fontSize: 12 }}>{getResultMessage(result)}</Text>
              </Row>
            )}
              </Col>
              <Col span={12}>
              <Text strong>Confidence level:</Text><br/>
                  <Text>{`liveness score: ${item.livenessScore}`}</Text>
                  <br/>
                  <Text>{`similarity score: ${item.similarityScore}`}</Text>
                <Space>
               
{/* 
                  <Tag color={item.similarityScore == 1 && item.livenessScore == 1 ? "green" :"orange" }>
                    {item.similarityScore == 1 && item.livenessScore == 1 ? "HIGH" : "MEDIUM"}
                  </Tag> */}
                </Space>
              </Col>
            </Row>
           
          </>
        )
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Link onClick={() => getDataAuthenDetail(record.request_id)}>View</Link>
          </Space>
        ),
        width: 80
      }
    ];

    return (
      <div>
        <Breadcrumb
          items={[
            {
              href: '',
              title: <><HomeOutlined /><span>Home</span></>
            },
            {
              title: <><UserOutlined /><span>Face Authentication Logs</span></>
            }
          ]}
        />
        <div style={{ display: 'flex', marginTop: 12 }}>
          <Select mode="multiple" style={{ width: 200 }} placeholder="Select Client" options={clients.map(c => ({ label: c.client_alias, value: c.client_id }))} />
          <Input style={{ maxWidth: 200, marginLeft: 12 }} placeholder="Search PID" value={userId} />
          <Input style={{ maxWidth: 200, marginLeft: 12 }} placeholder="Search Request ID" value={requestId} />
          <Select style={{ width: 200, marginLeft: 12 }} placeholder="Result Type" options={[{ label: 'SUCCESS', value: 1 }, { label: 'FAILED', value: 0 }]} />
          <RangePicker style={{ marginLeft: 12 }} value={dateSelected} showTime />
          <Button style={{ marginLeft: 12 }} type="primary" icon={<SearchOutlined />} onClick={this.getData}>Search</Button>
        </div>
        <Table loading={isLoading} style={{ marginTop: 20 }} columns={columns} dataSource={authenLogs} pagination={{
          current: currentPage, total, pageSize, onChange: (page, pageSize) => {
            this.setState({ currentPage: page, pageSize }, this.getData);
          }
        }} />
      </div>
    );
  }
}

export default withTranslation()(FaceAuthenticationLog);
