import StoreManager from "../appdata";
import { Button, message, Space } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from "jwt-decode";

let BASE_URL = "https://api.appturing.com"
// let BASE_URL = "http://localhost:3000"

export const loginAPI = (client_id, client_secret) => {
  fetch(`${BASE_URL}/auth/get-token`, {
    method: 'POST', // Phương thức HTTP
    headers: {
      // Thiết lập các headers
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      // Dữ liệu được gửi trong request body dưới dạng chuỗi JSON
      client_id: client_id,
      client_secret: client_secret
    })
  })
    .then(response => response.json()) // Chuyển đổi kết quả trả về thành JSON
    .then(data => {
      if (data && data.token != null) {
        setTimeout(()=>{
          StoreManager.userdata.token = data.token
          StoreManager.userdata.jwtDecode =  jwtDecode(data.token)
          console.log("StoreManager.userdata.jwtDecode", StoreManager.userdata.jwtDecode)
          StoreManager.updateDataToLocal()
          message.open({
            type: 'success',
            content: `Login Success`,
          })
          StoreManager.rootState.thisApp.setState({ isLogin: true })
        }, 1000)
     
      } else {
        message.open({
          type: 'error',
          content: `${data.error_description}`,
        })
        StoreManager.rootState.thisLogin.setState({ isLoading: false })

      }
    }) // Xử lý dữ liệu JSON
    .catch(error => {
      StoreManager.rootState.thisLogin.setState({ isLoading: false })
      console.error('Error:', error)
    } ); // Xử lý lỗi


}



export const getDataEnroll2  = (dateSelected, resultFilter, requestId, person_id, clients, pageSize, currentPage) => {
  let from_date = dateSelected[0].format('YYYY-MM-DD HH:mm:ss');
  let to_date = dateSelected[1].format('YYYY-MM-DD HH:mm:ss');

  fetch(`${BASE_URL}/admin/v1.0/enrolllogs?page=${currentPage}&limit=${pageSize}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    },
  })
    .then(response => response.json())
    .then(result => {
      console.log("result.data.data", result.data.data)
      if (result.error && result.error.code === 0) {  // Kiểm tra `error.code === 0`
        StoreManager.rootState.thisFaceEnrollLog.setState({
          enrollLogs: result.data.data,
          isLoading: false,
          total: result.data.pagination.total
        });
      } else {
        message.error(`Lỗi: ${result.error.message}`);
        StoreManager.rootState.thisFaceEnrollLog.setState({ isLoading: false });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      StoreManager.rootState.thisFaceEnrollLog.setState({ isLoading: false });
    });
}



export const getDataAuthenDetail  = ( requestId) => {

   fetch(`${BASE_URL}/v1.0/verify/detail?request_id=${requestId}`, {
    method: 'GET', // Phương thức HTTP
    headers: {
      // Thiết lập các headers
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    }
  })
    .then(response => response.json()) // Chuyển đổi kết quả trả về thành JSON
    .then(data => {
      if (data.result == 1) {
        StoreManager.rootState.thisFaceAuthenLog.setState({authenDetail: data.data, isLoading: false}, ()=>{
          StoreManager.rootState.thisFaceAuthenLog.showDetail()
        })

      } else {
        message.open({
          type: 'error',
          content: `${data.message}`,
        })
        StoreManager.rootState.thisFaceAuthenLog.setState({isLoading: false})

      }

     
    }) // Xử lý dữ liệu JSON
    .catch(error => console.error('Error:', error)); // Xử lý lỗi


}



export const getDataAuthen = (dateSelected, resultFilter, requestId, person_id, clients, pageSize, currentPage) => {
  const from_date = dateSelected[0].format('YYYY-MM-DD HH:mm:ss');
  const to_date = dateSelected[1].format('YYYY-MM-DD HH:mm:ss');

    fetch(`${BASE_URL}/admin/v1.0/authentications?page=${currentPage - 1}&size=${pageSize}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    },
  })
    .then(response => response.json())
    .then(result => {
      console.log("result.data.data", result.data.data)
      if (result.error && result.error.code === 0) {  // Kiểm tra `error.code === 0`
        StoreManager.rootState.thisFaceAuthenLog.setState({
          authenLogs: result.data.data,
          isLoading: false,
          total: result.data.pagination.total
        });
      } else {
        message.error(`Lỗi: ${result.error.message}`);
        StoreManager.rootState.thisFaceAuthenLog.setState({ isLoading: false });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      message.error('Đã xảy ra lỗi khi lấy dữ liệu!');
      throw error;
    });
};

export const getDataFaceMatch = (dateSelected, requestId, clientId, pageSize, currentPage) => {
  const from_date = dateSelected[0].format('YYYY-MM-DD HH:mm:ss');
  const to_date = dateSelected[1].format('YYYY-MM-DD HH:mm:ss');

    fetch(`${BASE_URL}/admin/v1.0/facematches?page=${currentPage - 1}&limit=${pageSize}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    },
  })
    .then(response => response.json())
    .then(result => {
      if (result.error && result.error.code === 0) {  // Kiểm tra `error.code === 0` để xác nhận không có lỗi
        StoreManager.rootState.thisFaceMatchLog.setState({
          matchLogs: result.data.data,
          isLoading: false,
          total: result.data.pagination.total
        });
      } else {
        message.error(`Lỗi: ${result.error.message}`);
        StoreManager.rootState.thisFaceMatchLog.setState({ isLoading: false });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      message.error('Đã xảy ra lỗi khi lấy dữ liệu FaceMatch!');
      throw error;
    });
};



export const getApiDashBoard = (dateSelected) => {
  let from_date = dateSelected[0].format('YYYY-MM-DD HH:mm:ss')
  let to_date = dateSelected[1].format('YYYY-MM-DD HH:mm:ss')

  const url2 = `/api/v1.0/dashboard/enroll-chart?from_date=${from_date}&to_date=${to_date}`;
  const url1 = `/api/v1.0/dashboard/overview?from_date=${from_date}&to_date=${to_date}`;
  const url3 = `/api/v1.0/dashboard/verify-chart?from_date=${from_date}&to_date=${to_date}`;

    const authHeader = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    };

    Promise.all([
        fetch(url1, { headers: authHeader }),
        fetch(url2, { headers: authHeader }),
        fetch(url3, { headers: authHeader })
    ]).then(responses => Promise.all(responses.map(res => res.json())))
      .then(data => {
          if (data[0].result == 1) {
            StoreManager.rootState.thisHomeScreen.setState({ overviewReport: data[0].data})

          }
          if (data[1].result == 1) {
            StoreManager.rootState.thisHomeScreen.setState({enrollReport: data[1].data})

          }
          if (data[2].result == 1) {
            StoreManager.rootState.thisHomeScreen.setState({authenReport: data[2].data})

          }

          // Xử lý dữ liệu tại đây
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });

}

export const uploadFaceVerify  = (personId, faceImage) => {

  fetch(`/face/v1.0/match`, {
    method: 'POST', // Phương thức HTTP
    headers: {
      'ClientId': `143918e1232989fea4faec54edbad19a`,
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    },
    body: JSON.stringify({
      transId: uuidv4(),
      personId,
      faceImage
    })
  })
    .then(response => response.json()) // Chuyển đổi kết quả trả về thành JSON
    .then(data => {
      if (data.result == 1) {
        setTimeout(()=>{       
           StoreManager.rootState.thisManualVerify.setState({resultData: data, isLoading: false})
      },500)
      } else {
        message.open({
          type: 'error',
          content: `Authentication Failed`,
        })
        StoreManager.rootState.thisManualVerify.setState({resultData: data,isLoading: false})

      }

     
    }) // Xử lý dữ liệu JSON
    .catch(error => console.error('Error:', error)); // Xử lý lỗi


}

export const getClients  = () => {
  fetch(`/csp/v1.0/account/clients`, {
    method: 'GET', // Phương thức HTTP
    headers: {
      // Thiết lập các headers
      'Content-Type': 'application/json;charset=UTF-8',
      'Clientid':'143918e1232989fea4faec54edbad19a',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    }
  })
    .then(response => response.json()) // Chuyển đổi kết quả trả về thành JSON
    .then(data => {

      if (data.errorCode == 1) {
        setTimeout(()=>{       
          StoreManager.rootState.thisClientScreen.setState({clients: data.data.reverse(), isLoading: false})
     },500)
      } else {
        StoreManager.rootState.thisClientScreen.setState({clients: [], isLoading: false})

        message.open({
          type: 'error',
          content: `${data.message}`,
        })

      }

     
    }) // Xử lý dữ liệu JSON
    .catch(error => console.error('Error:', error)); // Xử lý lỗi
}

export const getClientsWidthCallBack  = (callback) => {
  fetch(`/csp/v1.0/account/clients`, {
    method: 'GET', // Phương thức HTTP
    headers: {
      // Thiết lập các headers
      'Content-Type': 'application/json;charset=UTF-8',
      'Clientid':'143918e1232989fea4faec54edbad19a',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    }
  })
    .then(response => response.json()) // Chuyển đổi kết quả trả về thành JSON
    .then(data => {

      if (data.errorCode == 1) {
        setTimeout(()=>{  
          callback(data.data.reverse())
          // StoreManager.rootState.thisClientScreen.setState({clients: data.data.reverse(), isLoading: false})
     },500)
      } else {
        // StoreManager.rootState.thisClientScreen.setState({clients: [], isLoading: false})
        callback([])

        message.open({
          type: 'error',
          content: `${data.message}`,
        })

      }

     
    }) // Xử lý dữ liệu JSON
    .catch(error => console.error('Error:', error)); // Xử lý lỗi
}

export const createClient  = (email,alias) => {

  fetch(`/csp/v1.0/account/clients/create`, {
    method: 'POST', // Phương thức HTTP
    headers: {
      // Thiết lập các headers
      'Content-Type': 'application/json;charset=UTF-8',
      'Clientid':'143918e1232989fea4faec54edbad19a',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    },
    body: JSON.stringify({
      // Dữ liệu được gửi trong request body dưới dạng chuỗi JSON
      email: email,
      alias: alias
    })
  })
    .then(response => response.json()) // Chuyển đổi kết quả trả về thành JSON
    .then(data => {
      if (data.errorCode == 1) {
        StoreManager.rootState.thisClientScreen.setState({ isCreateLoading: false, isShowCreate: false}, ()=>{
          StoreManager.rootState.thisClientScreen.getData()
        })

      } else {
        StoreManager.rootState.thisClientScreen.setState({ isCreateLoading: false})
      }

     
    }) // Xử lý dữ liệu JSON
    .catch(error => console.error('Error:', error)); // Xử lý lỗi


}

export const getDataLivenessLog = (dateSelected, clientId, requestId, pageSize, currentPage) => {
  const from_date = dateSelected[0].format('YYYY-MM-DD HH:mm:ss');
  const to_date = dateSelected[1].format('YYYY-MM-DD HH:mm:ss');

  fetch(`${BASE_URL}/admin/v1.0/detect-liveness-logs?page=${currentPage}&limit=${pageSize}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${StoreManager.userdata.token}`
    },
  })
    .then(response => response.json())
    .then(result => {
      if (result.error && result.error.code === 0) {  // Kiểm tra `error.code === 0` để xác nhận không có lỗi
        StoreManager.rootState.thisLivenessLog.setState({
          livenessLogs: result.data.data,
          isLoading: false,
          total: result.data.pagination.total
        });
      } else {
        message.error(`Lỗi: ${result.error.message}`);
        StoreManager.rootState.thisLivenessLog.setState({ isLoading: false });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      message.error('Đã xảy ra lỗi khi lấy dữ liệu Liveness Log!');
      throw error;
    });
};


