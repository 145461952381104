import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { HomeOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { Image, Space, Table, Tag, Breadcrumb, Input, Select, DatePicker, Button, message, Modal, Row, Col, Descriptions, Statistic, Typography } from 'antd';
import { getDataFaceMatch } from '../apis'; // Đảm bảo API đã được định nghĩa
import StoreManager from '../appdata';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Text } = Typography;

class FaceMatchLog extends Component {
  constructor(props) {
    super(props);
    const today = dayjs().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 0);
    const sevenDaysAgo = dayjs().subtract(7, 'day').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);

    this.state = {
      matchLogs: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dateSelected: [sevenDaysAgo, today],
      isLoading: true,
      clientId: '',
      requestId: '',
    };

    this.getData();
    StoreManager.rootState.thisFaceMatchLog = this;
  }

  getData = () => {
    const { dateSelected, pageSize, currentPage, clientId, requestId } = this.state;
    if (dateSelected == null) {
      message.warning("Input Error!");
      return;
    }
    this.setState({ isLoading: true });
    getDataFaceMatch(dateSelected, clientId, requestId, pageSize, currentPage);
  };

  // Nội dung trang FaceMatchLog
  render() {
    const { matchLogs, dateSelected, isLoading, currentPage, total, pageSize, clientId, requestId } = this.state;

    const columns = [
      {
        title: 'Time',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: (time) => (
          <Text>{dayjs(time).format('HH:mm DD-MM-YYYY')}</Text>
        ),
      },
      {
        title: 'Image 1',
        dataIndex: 'faceImage1',
        key: 'faceImage1',
        render: (text) => (
          <Image width={50} height={50} src={text} />
        ),
      },
      {
        title: 'Image 2',
        dataIndex: 'faceImage2',
        key: 'faceImage2',
        render: (text) => (
          <Image width={50} height={50} src={text} />
        ),
      },
      {
        title: 'RequestID',
        dataIndex: 'requestId',
        key: 'requestId',
        width: 180,
      },
      {
        title: 'Result',
        dataIndex: 'isMatched',
        key: 'isMatched',
        render: (isMatched) => (
          <Tag color={isMatched ? 'green' : 'red'}>
            {isMatched ? 'MATCHED' : 'NOT MATCHED'}
          </Tag>
        ),
      },
      {
        title: 'Confidence Score',
        dataIndex: 'matchingScore',
        key: 'matchingScore',
        render: (score) => (
          <Text>{`${score}%`}</Text>
        ),
      },
    ];

    let scrollY = window.innerHeight - 352;

    return (
      <div>
        <Breadcrumb
          items={[
            {
              href: '',
              title: <><HomeOutlined /><span>Home</span></>,
            },
            {
              title: (
                <>
                  <UserOutlined />
                  <span>Face Match logs</span>
                </>
              ),
            },
          ]}
        />
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
          <Input
            style={{ maxWidth: 200, marginLeft: 12 }}
            placeholder="Search Client ID"
            value={clientId}
            onChange={(e) => {
              this.setState({ clientId: e.target.value });
            }}
          />
          <Input
            style={{ maxWidth: 200, marginLeft: 12 }}
            placeholder="Search Request ID"
            value={requestId}
            onChange={(e) => {
              this.setState({ requestId: e.target.value });
            }}
          />
          <RangePicker
            style={{ marginLeft: 12 }}
            value={dateSelected}
            showTime
            onChange={(dates, dateStrings) => {
              this.setState({ dateSelected: dates });
            }}
          />
          <Button
            style={{ marginLeft: 12 }}
            type="primary"
            icon={<SearchOutlined />}
            onClick={this.getData}
          >
            Search
          </Button>
        </div>
        <Table
          loading={isLoading}
          style={{ marginTop: 20 }}
          scroll={{ y: scrollY }}
          columns={columns}
          dataSource={matchLogs}
          bordered
          pagination={{
            current: currentPage,
            total: total,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              this.setState({ currentPage: page, pageSize: pageSize }, () => {
                this.getData();
              });
            },
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(FaceMatchLog);
