import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Upload, Form, Input, Image, Breadcrumb, Table, Tag, Row, Col,Statistic } from 'antd';
import { CheckCircleOutlined, SafetyCertificateOutlined, UploadOutlined, HomeOutlined ,ExclamationCircleOutlined} from '@ant-design/icons';
import { uploadFaceVerify } from '../apis';
import StoreManager from '../appdata';
import dayjs from 'dayjs';


class ManualVerifyScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            base64Face: '',
            resultData: false
        };
        StoreManager.rootState.thisManualVerify = this;
        this.formRef = React.createRef(); // Thêm dòng này

    }

    handleFileRemove = () => {
        this.setState({ base64Face: '', resultData: false });

    }


    beforeUpload = (file, fileList) => {

        console.log("info.file", file, fileList)
        const reader = new FileReader();
        reader.onload = () => {
            // Cập nhật state với chuỗi base64 của ảnh
            var cleanBase64 = reader.result.replace(/^data:.+;base64,/, "");
            this.setState({ base64Face: cleanBase64 });
            console.log("reader.result ", cleanBase64)
            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");

            this.formRef.current.setFieldsValue({
                idnumber: fileNameWithoutExtension // Sử dụng tên file
            });
        };
        reader.readAsDataURL(file);
        reader.onerror = (error) => {
            console.log('Error reading file:', error);
        };
        // Trả về false để ngăn chặn việc upload
        return false;
    };

    render() {
        const { isLoading, resultData, base64Face } = this.state;

        const uploadProps = {
            name: 'file',
            listType: 'picture',
            beforeUpload: this.beforeUpload,
            onRemove: this.handleFileRemove,
            multiple: false,
            maxCount: 1
        };
        const columns = [
   
            {
              title: 'Time',
              dataIndex: 'created_at',
              key: 'created_at',
              width: 180, 
              render: (text) => <>
                <p>{`${dayjs().format('HH:mm:ss DD/MM/YYYY')}`}</p>
              </>
            },
            {
              title: 'Image',
              dataIndex: 'image_url',
              key: 'image_url',
              width: 100,
              render: (text) =>  <Image
              width={50}
              height={50}
              src={`data:image/jpeg;base64,${base64Face}`}
            />
      
            },
            {
              title: 'RequestID',
              dataIndex: 'requestId',
              key: 'requestId',
              width: 180
      
            },
            {
              title: 'Transaction ID',
              dataIndex: 'transId',
              key: 'transId',
              width: 180
      
            },
            
            {
              title: 'Result',
              dataIndex: 'confident_score',
              key: 'confident_score',
              width: 360,
      
              render: (data,item) => {
                console.log("item", item)
                var tmp = <></>
                switch (item.result) {
                    case 1:
                        tmp = <Tag color="green">SUCCSESS</Tag>
                        break;
                       case 0:
                        tmp = <Tag color="orange">FAILED</Tag>
                        break;
                        // case -1:
                        //     tmp = <Tag color="orange">USER ID NOT EXISTS</Tag>
                        // break;
                        // case -2:
                        //     tmp = <Tag color="orange">LIVENESS FAILED</Tag>
      
                        // break;
                        // case -3:
                        //     tmp = <Tag color="orange">LOW CONFIDENCE SCORE</Tag>
      
                        // break;
                
                    default:
                        break;
                }
                return <>
                  {tmp}
                  <Row>
                  <Col span={6}>            <Statistic title="Face Detect:" prefix={item.data?.faceDetection?.success  ? <CheckCircleOutlined style={{color:'green'}} /> :  <ExclamationCircleOutlined style={{color:'orange'}} />} valueStyle={{fontSize: 14}}  value={`${item.data?.faceDetection?.success}`}/>
      </Col>
                    <Col span={6}>            <Statistic title="Liveness:" prefix={item.data?.liveness?.success ? <CheckCircleOutlined style={{color:'green'}} /> :  <ExclamationCircleOutlined style={{color:'orange'}} />} valueStyle={{fontSize: 14}}  value={`${(item.data?.liveness?.score * 100).toString().slice(0,5)}%`}/>
      </Col>
                    <Col span={6}>            <Statistic title="Match:" prefix={item.data?.faceMatch?.success ? <CheckCircleOutlined style={{color:'green'}} /> :  <ExclamationCircleOutlined style={{color:'orange'}} />} valueStyle={{fontSize: 14}}  value={`${(item.data?.faceMatch?.score * 100).toString().slice(0,5)}%`}/>
      </Col>
      <Col span={6}>            <Statistic title="BioID Found:" prefix={item.data?.faceMatch?.success  ? <CheckCircleOutlined style={{color:'green'}} /> :  <ExclamationCircleOutlined style={{color:'orange'}} />} valueStyle={{fontSize: 14}}  value={`${item.data?.faceMatch?.bioFound}`}/>
      </Col>
    
      
                  </Row>
      
                  {/* <p>{`Match score: ${item.match_score}`}</p> */}
      
                </>
              }
            }
          ];

        return (
            <div style={{ height: '100vh', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Breadcrumb
                        items={[
                            {
                                href: '',
                                title: <> <HomeOutlined />
                                    <span>Home</span>
                                </>,
                            },
                            {
                                title: (
                                    <>
                                        <SafetyCertificateOutlined />
                                        <span>FaceVerify Tool</span>
                                    </>
                                ),
                            }
                        ]}
                    />



                </div>
                <Form
                    ref={this.formRef} // Gán ref vào đây
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    style={{ width: 400, marginTop: 20 }}
                    onFinish={(values) => {
                        console.log("values", values)
                        const { base64Face } = this.state;
                        if (base64Face == '') {
                            return
                        }
                        uploadFaceVerify(values.idnumber, base64Face)
                        this.setState({ isLoading: true })

                    }}
                    onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="CCCD number"
                        name="idnumber"
                        rules={[{ required: true, message: 'Please input your id number!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Upload Face"
                        name="face"
                    >
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />}>Click to select Face</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button loading={isLoading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                {
                    resultData ?  <Table style={{marginTop: 20}} columns={columns} dataSource={[resultData]} bordered /> : null
                }
            </div>
        );
    }
}

export default withTranslation()(ManualVerifyScreen);
