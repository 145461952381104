import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './custom-antd.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <ConfigProvider theme={{
                token: {
                    fontFamily: 'Roboto',
                },
            }}>
        <App />

      </ConfigProvider>
    </BrowserRouter>
);
reportWebVitals();
