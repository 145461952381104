import React, { Component } from 'react';
import { Route,Switch } from "react-router-dom";
import AppMenu from './components/AppMenu';
import HomeScreen from './screens/HomeScreen';
import FaceEnrollLog from './screens/FaceEnrollLog';
import FaceAuthenticationLog from './screens/FaceAuthenticationLog';
import NotFoundScreen from './screens/NotFoundScreen';
import FaceMatchLog from './screens/FaceMatchLog';
import LivenessLog from './screens/LivenessLog';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Layout, Button, Image, Avatar, Dropdown } from 'antd';

import resource from './resource';
import StoreManager from './appdata';
import LoginScreen from './screens/LoginScreen';
import ManualVerifyScreen from './screens/ManualVerifyScreen';
import ClientScreen from './screens/ClientScreen';

import "./App.css"

StoreManager.loadDataFromLocal();


const { Header, Sider, Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      isLogin: StoreManager.userdata.token != ''
    }
    StoreManager.rootState.thisApp = this
  }
  render() {
    const { collapsed, isLogin } = this.state
    if (isLogin) {
      return (
        <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
        <Header style={{ padding: 0, background: 'white', boxShadow: '0 2px 8px rgba(0,0,0,0.1)'}}>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{marginLeft: collapsed ? 8: 4}}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => this.setState({'collapsed': !collapsed})}
                style={{ fontSize: '16px', width: 64, height: 64 }}
              />
              <Image style={{ marginLeft: 15 }} width={100} src={resource.images.trueidlogo} preview={false} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center',marginRight: 20 }}>
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    icon: <UserOutlined />,
                    label: (
                      <span onClick={() => {

                      }}>
                       {`Account`}
                      </span>
                    ),
                  },
                  {
                    key: '2',
                    icon: <LogoutOutlined />,
                    label: (
                      <span onClick={() => {
                        StoreManager.userdata.token = ''
                        this.setState({'isLogin': false})
                      }}>
                      {`Logout`}
                     </span>
                    ),
                  },
                ],
              }}
            >
              <span className="user-action">
                <Avatar
                  style={{ backgroundColor: 'green' }}
                  size="default"
                >
                  {`A D`}
                </Avatar>
              </span>
            </Dropdown>
             
            </div>
          </div>
        </Header>
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
            <AppMenu collapsed={collapsed}/>
          </Sider>
          <Content style={{ margin: '16px 16px', padding: 24, minHeight: 280, background: 'white', borderRadius: 5 }}>
            <Switch>
              <Route exact path="/" component={HomeScreen} />
              <Route  path="/logs-authentication" component={FaceAuthenticationLog} />
              <Route  path="/logs-enroll" component={FaceEnrollLog} />
              <Route  path="/face-verify" component={ManualVerifyScreen} />
              <Route  path="/face-match" component={FaceMatchLog} />
              <Route  path="/logs-liveness" component={LivenessLog} />

              {
                StoreManager.userdata.jwtDecode?.role == "ADMIN" ?  <Route  path="/list-client" component={ClientScreen} /> : null
              }
             

              <Route component={NotFoundScreen} />

            </Switch>


          </Content>
        </Layout>
      </Layout>
      
      );
    } else {
      return <LoginScreen />
    }
    
  }
}

export default App;
