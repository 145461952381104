import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { HomeOutlined, UserOutlined, SearchOutlined, UsergroupAddOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { Image, Space, Card, Tag, Breadcrumb, Input, Select, DatePicker, Button, message, BackTop, Row, Col } from 'antd';
import { getApiDashBoard } from '../apis';
import StoreManager from '../appdata';
import dayjs from 'dayjs';
import CountUp from 'react-countup'
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

import 'chart.js/auto';


const { RangePicker } = DatePicker;




class HomeScreen extends Component {
  constructor(props) {
    super(props)
    const today = dayjs().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 0);

    // Đặt giá trị cho `sevenDaysAgo` là 0h00 của 7 ngày trước đó
    const sevenDaysAgo = dayjs().subtract(14, 'day').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);


    this.state = {
      enrollReport: [],
      authenReport: [],
      overviewReport: false,
      dateSelected: [sevenDaysAgo, today],
      isLoading: true,
      resultFilter: null,
    }

    StoreManager.rootState.thisHomeScreen = this

  }

  getData = () => {
    const { dateSelected } = this.state
    getApiDashBoard(dateSelected)
  }
  componentDidMount() {
    this.getData()
  }

  // Nội dung trang Face Enroll Log
  render() {
    let scrollY = window.innerHeight - 200

    const { overviewReport, authenReport, enrollReport, isLoading, dateSelected } = this.state

    const sortedEnrollReport = enrollReport.sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
    // Xử lý dữ liệu cho biểu đồ
    const enrollLabels = sortedEnrollReport.map(item => item.date);
    const userEnrollSuccessData = sortedEnrollReport.map(item => item.success);
    const userEnrollFailedData = sortedEnrollReport.map(item => item.failed);
    const chartEnrollData = {
      labels: enrollLabels,
      datasets: [
        {
          label: 'Enroll Success',
          data: userEnrollSuccessData,
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderColor: 'rgba(53, 162, 235, 1)',
          borderWidth: 1,
        },
        {
          label: 'Enroll Failed',
          data: userEnrollFailedData,
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        }
      ]
    };



    const sortedAuthenReport = authenReport.sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
    // Xử lý dữ liệu cho biểu đồ
    const authenLabels = sortedAuthenReport.map(item => item.date);
    const userAuthenSuccessData = sortedAuthenReport.map(item => item.success);
    const userAuthenFailedData = sortedAuthenReport.map(item => item.failed);
    const chartAuthenData = {
      labels: authenLabels,
      datasets: [
        {
          label: 'Authentication success',
          data: userAuthenSuccessData,
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderColor: 'rgba(53, 162, 235, 1)',
          borderWidth: 1,
        },
        {
          label: 'Authentication failed',
          data: userAuthenFailedData,
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        }
      ]
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            // Định dạng lại giá trị trục y
            callback: function (value, index, values) {
              return value.toLocaleString();
            }
          }
        },
        x: {
          // Cấu hình thêm cho trục x nếu bạn muốn
        }
      },
      plugins: {
        legend: {
          position: 'top', // Đặt chú thích ở trên cùng
        },
        tooltip: {
          enabled: true,
          // Định dạng tooltip
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y.toLocaleString();
              }
              return label;
            }
          }
        }
      },
      hover: {
        // Cấu hình thêm cho hiệu ứng khi di chuột qua
      },
      // Cấu hình thêm để tạo ra hiệu ứng animation đẹp mắt
      animation: {
        tension: {
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 0,
          loop: true
        }
      }
    };



    return (<div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Breadcrumb
          items={[
            {
              href: '',
              title: <> <HomeOutlined />
                <span>Home</span>
              </>,
            },
            {
              title: (
                <>
                  <UserOutlined />
                  <span>Dashboard</span>
                </>
              ),
            }
          ]}
        />

        <div style={{ display: 'flex', flexDirection: 'row' }}>

          <RangePicker style={{ marginLeft: 12 }} value={dateSelected} showTime onChange={(dates, dateStrings) => {
            this.setState({ 'dateSelected': dates })
          }} />
          <Button style={{ marginLeft: 12 }} type="primary" icon={<SearchOutlined />} onClick={this.getData}>Search</Button>


        </div>

      </div>

        <div style={{ height: scrollY, overflow: 'scroll', padding: 12 }}>


          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Card style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",  }}  size='small'>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: 10 }}>
                <UserOutlined style={{ fontSize: 60, color: '#8fc9fb' }} />

                <div style={{ marginLeft: 20 }}>
                  <p style={{ fontSize: 16, height: 16, color: '#666' }}>Authentication:</p>
                  <p style={{ fontSize: 24, color: '#666', fontWeight: 'bold' }}>
                    {
                      overviewReport ? <CountUp
                        start={0}
                        end={overviewReport.verify_request.totalSuccess}
                        duration={2.75}
                        useEasing
                        useGrouping
                        separator=","
                      /> : "0"
                    }
                  </p>
                </div>
              </div>

            </Card>
            <Card style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",marginLeft: 20  }} size='small' >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: 10 }}>
                <UsergroupAddOutlined style={{ fontSize: 60, color: '#64ea91' }} />

                <div style={{ marginLeft: 20 }}>
                  <p style={{ fontSize: 16, height: 16, color: '#666' }}>Enrollment:</p>
                  <p style={{ fontSize: 24, color: '#666', fontWeight: 'bold' }}>
                    {
                      overviewReport ? <CountUp
                        start={0}
                        end={overviewReport.enroll_request.totalSuccess}
                        duration={2.75}
                        useEasing
                        useGrouping
                        separator=","
                      /> : "0"
                    }

                  </p>
                </div>
              </div>

            </Card>
           
            <Card style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)", marginLeft: 20 }}  size='small' >
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: 10 }}>
                <IssuesCloseOutlined style={{ fontSize: 60, color: '#f69899' }} />

                <div style={{ marginLeft: 20 }}>
                  <p style={{ fontSize: 16, height: 16, color: '#666' }}> Issue case:</p>
                  <p style={{ fontSize: 24, color: '#666', fontWeight: 'bold' }}>
                    {
                      overviewReport ? <CountUp
                        start={0}
                        end={overviewReport.verify_request.totalFail + overviewReport.enroll_request.totalFail}
                        duration={2.75}
                        useEasing
                        useGrouping
                        separator=","
                      /> : "0"
                    }
                  </p>
                </div>
              </div>

            </Card>
          </div>
          <Row>
            <Col span={11}>
            <Card style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)", marginTop: 20 }} title={'Authentication'}>
              <Bar data={chartAuthenData} options={options} />
            </Card>
           

            </Col>
            <Col span={1}>

            </Col>
            <Col span={11}>
            <Card style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)", marginTop: 20 }} title={'Enrollment'}>
              <Bar data={chartEnrollData} options={options} />
            </Card>
            </Col>

          </Row>


         
        </div>



    </div>)
  }
}

export default withTranslation()(HomeScreen);