import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { HomeOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { Image, Space, Table, Tag, Breadcrumb, Input, DatePicker, Button, message, Typography } from 'antd';
import { getDataLivenessLog } from '../apis'; // API lấy dữ liệu log liveness
import StoreManager from '../appdata';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Text } = Typography;

class LivenessLog extends Component {
  constructor(props) {
    super(props);
    const today = dayjs().set('hour', 23).set('minute', 59).set('second', 59).set('millisecond', 0);
    const sevenDaysAgo = dayjs().subtract(7, 'day').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);

    this.state = {
      livenessLogs: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dateSelected: [sevenDaysAgo, today],
      isLoading: true,
      clientId: '',
      requestId: '',
    };

    this.getData();
    StoreManager.rootState.thisLivenessLog = this;
  }

  getData = () => {
    const { dateSelected, pageSize, currentPage, clientId, requestId } = this.state;
    if (dateSelected == null) {
      message.warning("Input Error!");
      return;
    }
    this.setState({ isLoading: true });
    getDataLivenessLog(dateSelected, clientId, requestId, pageSize, currentPage);
  };

  render() {
    const { livenessLogs, dateSelected, isLoading, currentPage, total, pageSize, clientId, requestId } = this.state;

    const columns = [
      {
        title: 'Time',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: (time) => (
          <Text>{dayjs(time).format('HH:mm DD-MM-YYYY')}</Text>
        ),
      },
      {
        title: 'Image',
        dataIndex: 'faceImages',
        key: 'faceImages',
        render: (images) => (
          <Space>
            {images.map((image, index) => (
              <Image key={index} width={50} height={50} src={image} />
            ))}
          </Space>
        ),
      },
      {
        title: 'RequestID',
        dataIndex: 'requestId',
        key: 'requestId',
        width: 180,
      },
      {
        title: 'Result',
        dataIndex: 'isLive',
        key: 'isLive',
        render: (isLive) => (
          <Tag color={isLive ? 'green' : 'red'}>
            {isLive ? 'LIVE' : 'NOT LIVE'}
          </Tag>
        ),
      },
      {
        title: 'Liveness Score',
        dataIndex: 'livenessScore',
        key: 'livenessScore',
        render: (score) => (
            <Text>{`${(score * 100).toFixed(2)}%`}</Text>
        ),
      },
    ];

    let scrollY = window.innerHeight - 352;

    return (
      <div>
        <Breadcrumb
          items={[
            {
              href: '',
              title: <><HomeOutlined /><span>Home</span></>,
            },
            {
              title: (
                <>
                  <UserOutlined />
                  <span>Liveness logs</span>
                </>
              ),
            },
          ]}
        />
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
          <Input
            style={{ maxWidth: 200, marginLeft: 12 }}
            placeholder="Search Client ID"
            value={clientId}
            onChange={(e) => {
              this.setState({ clientId: e.target.value });
            }}
          />
          <Input
            style={{ maxWidth: 200, marginLeft: 12 }}
            placeholder="Search Request ID"
            value={requestId}
            onChange={(e) => {
              this.setState({ requestId: e.target.value });
            }}
          />
          <RangePicker
            style={{ marginLeft: 12 }}
            value={dateSelected}
            showTime
            onChange={(dates, dateStrings) => {
              this.setState({ dateSelected: dates });
            }}
          />
          <Button
            style={{ marginLeft: 12 }}
            type="primary"
            icon={<SearchOutlined />}
            onClick={this.getData}
          >
            Search
          </Button>
        </div>
        <Table
          loading={isLoading}
          style={{ marginTop: 20 }}
          scroll={{ y: scrollY }}
          columns={columns}
          dataSource={livenessLogs}
          bordered
          pagination={{
            current: currentPage,
            total: total,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              this.setState({ currentPage: page, pageSize: pageSize }, () => {
                this.getData();
              });
            },
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(LivenessLog);
